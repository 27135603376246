import { useBanks } from 'api/defaultApi';
import SelectControl, { SelectControlProps } from 'common/forms/SelectControl';
import { Bank, BankListResponse } from 'generated';
import withData from 'HOC/withData';

const BankSelect = withData<BankListResponse, Bank[], any, Omit<SelectControlProps, 'options'>>(
  SelectControl,
  {
    propsKey: 'options',
    selector: (response) =>
      response.entries.map((item) => ({
        key: item.id,
        value: item.id,
        label: `${item.name}${item.code ? ` (${item.code})` : ''}`,
      })),
    useFetcher: useBanks,
  },
);

export default BankSelect;
