import { SelectOption } from '@pluxee-design-system/react';
import { useContractProductLocations } from 'api/contractApi';
import SelectControl from 'common/forms/SelectControl';
import MenuListComponent from 'components/Form/LocationSelect/MenuListComponent';
import useCurrentContract from 'data/useCurrentContract';
import { FormikValues, useFormikContext } from 'formik';
import useTranslations from 'i18n';
import { debounce, get, isArray } from 'lodash';
import { UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InputActionMeta, MenuListProps, MultiValue, PropsValue, SingleValue } from 'react-select';
import OptionComponent from './OptionComponent';
import {
  LocationSelectOnChangeProps,
  LocationSelectProps,
  LocationType,
  OptionComponentProps,
  ProductLocationFilterValues,
} from './types';
import { getLabel, getValue, id } from './utils';

const LocationSelect = ({
  getDefaultOption,
  getOptionLabel = getLabel,
  getOptionValue = getValue,
  defaultFilterState,
  disabled,
  options,
  onChange,
  hasNextPage: optionsHasNextPage,
  isEmpty: optionsIsEmpty,
  isCompact,
  isMulti,
  name,
  isNextPageLoading: optionsIsNextPageLoading,
  loadNextPage: optionsLoadNextPage,
  filterState: optionsFilterState,
  setFilterState: optionsSetFilterState,
  ...rest
}: LocationSelectProps & LocationSelectOnChangeProps) => {
  const { t } = useTranslations();
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [inputValue, setInputValue] = useState('');
  const value = get(values, name, null) as string | string[] | null;
  const hasOptions = options !== undefined;
  const scrollOffsetRef = useRef<number>(0);
  const [dataFilterState, setDataFilterState] = useState<Partial<ProductLocationFilterValues>>(
    () => ({
      ...defaultFilterState,
    }),
  );
  const { contractId } = useCurrentContract(!hasOptions); // to disable
  const {
    data: locations,
    hasNextPage: dataHasNextPage,
    isEmpty: dataIsEmpty,
    isLoadingPage: dataIsLoadingPage,
    loadNextPage: dataLoadNextPage,
  } = useContractProductLocations(
    !hasOptions ? contractId : undefined, // to disable
    undefined,
    true,
    dataFilterState,
  );

  // use options or fetch data?
  const data = (hasOptions ? options : locations) as LocationType[];
  const hasNextPage = optionsHasNextPage ?? dataHasNextPage;
  const isEmpty = optionsIsEmpty ?? dataIsEmpty;
  const isLoadingPage = optionsIsNextPageLoading ?? dataIsLoadingPage;
  const loadNextPage = optionsLoadNextPage ?? dataLoadNextPage;
  const filterState = optionsFilterState ?? dataFilterState;
  const setFilterState = optionsSetFilterState ?? setDataFilterState;

  const debouncedSetInputValue = useMemo(
    () =>
      debounce((fulltext: string) => {
        scrollOffsetRef.current = 0; // reset scroll
        setFilterState((p) => ({ ...p, fulltext }));
      }, 1000),
    [setFilterState],
  );
  const handleScroll = useCallback((e: UIEvent<HTMLDivElement>) => {
    e.stopPropagation();
    scrollOffsetRef.current = (e.target as HTMLDivElement)?.scrollTop ?? 0;
  }, []);
  const handleInputChange = useCallback(
    (newValue: string, { action }: InputActionMeta) => {
      if (isMulti && action === 'set-value') {
        return false;
      }
      setInputValue(newValue);
      debouncedSetInputValue(newValue);
    },
    [isMulti, debouncedSetInputValue, setInputValue],
  );
  const handleChange = useCallback(
    (option: PropsValue<SelectOption>) => {
      if (onChange) {
        // @ts-ignore
        onChange(option);
      } else {
        setFieldValue(
          name,
          isMulti
            ? (option as MultiValue<SelectOption>).map((item: SelectOption) =>
                getOptionValue(item as unknown as LocationType),
              )
            : getOptionValue(option as unknown as LocationType),
        );
      }
    },
    [getOptionValue, isMulti, name, onChange, setFieldValue],
  );
  const currentValue = useMemo<PropsValue<SelectOption>>(() => {
    if (data) {
      if (isMulti) {
        const normalizedValue = isArray(value) ? value : [value];
        const usedValues = new Set<string>();
        const result = data.filter((option) => {
          const optionValue = getOptionValue(option);
          if (normalizedValue.includes(optionValue)) {
            usedValues.add(optionValue);
            return true;
          }
          return false;
        });
        // values not present in options
        normalizedValue.forEach((val) => {
          const optionValue = getDefaultOption?.(val);
          if (val && !usedValues.has(val) && optionValue) {
            result.push(optionValue);
          }
        });
        return result as unknown as MultiValue<SelectOption>;
      }
      return (data.find((option) => getOptionValue(option as LocationType) == value) ||
        getDefaultOption?.(value as unknown as string) ||
        null) as unknown as SingleValue<SelectOption>;
    }
    return isMulti ? ([] as MultiValue<SelectOption>) : (null as SingleValue<SelectOption>);
  }, [data, isMulti, getDefaultOption, getOptionValue, value]);

  const MenuList = useMemo(
    () => (props: MenuListProps<SelectOption, true>) =>
      MenuListComponent(props, {
        data,
        filterState,
        hasNextPage,
        isEmpty,
        isLoadingPage,
        loadNextPage,
        handleScroll,
        scrollOffsetRef,
      }),
    [data, filterState, hasNextPage, isEmpty, isLoadingPage, loadNextPage, handleScroll],
  );

  const Option = useMemo(
    () => (props: OptionComponentProps) => OptionComponent(props, { isCompact, t }),
    [isCompact, t],
  );

  useEffect(
    () => setDataFilterState({ ...defaultFilterState }),
    [defaultFilterState, setDataFilterState],
  );

  return (
    <SelectControl
      // react-selects props passed by ...rest
      {...rest}
      value={currentValue}
      name={name}
      isMulti={isMulti}
      onChange={handleChange}
      customComponents={{ MenuList, Option }}
      sortAlphabetically={false}
      // @ts-ignore
      options={data}
      isSearchInput
      filterOption={id}
      isLoading={isLoadingPage}
      isSearchable={true}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      // menuIsOpen
      // menuShouldBlockScroll
    />
  );
};

export default LocationSelect;
