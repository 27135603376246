import CheckboxGroupControl, { CheckboxGroupControlProps } from 'common/forms/CheckboxGroupControl';
import SelectControl, { SelectControlProps } from 'common/forms/SelectControl';
import ServiceDetail from './ServiceDetail';

type CommonProps = SelectControlProps | CheckboxGroupControlProps;

export type ServiceListProps = CommonProps & {
  variant?: 'multiple' | 'single';
  notFoundText?: string;
  isLoading?: boolean;
};

const ServiceList = ({ variant = 'multiple', isLoading, ...props }: ServiceListProps) =>
  variant === 'multiple' ? (
    <CheckboxGroupControl {...props} renderComponent={ServiceDetail} />
  ) : (
    <SelectControl isLoading={isLoading} {...props} isArrayValue />
  );

export default ServiceList;
