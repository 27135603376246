import { Box, Flex, SimpleGrid, Spacer } from '@chakra-ui/react';
import { Icons, SvgSpriteConsumer, Text } from '@pluxee-design-system/react';
import { isLocationItem } from 'components/Form/LocationSelect/utils';
import { TranslateFn } from 'i18n';
import { components } from 'react-select';
import { OptionComponentProps } from './types';

interface ExtraComponentProps {
  isCompact?: boolean;
  t: TranslateFn;
}

const OptionComponent = (
  { isSelected, label, data, ...props }: OptionComponentProps,
  { isCompact, t }: ExtraComponentProps,
) => {
  const address = isLocationItem(data) ? data?.location?.address : data?.address;
  const masterId = isLocationItem(data) ? data?.location?.masterId : data?.masterId;
  const merchantLocationId = isLocationItem(data)
    ? data?.location?.merchantLocationId
    : data?.merchantLocationId;

  return (
    <components.Option label={label} isSelected={isSelected} data={data} {...props}>
      <Flex alignItems="center" width="100%" justify="space-between">
        {data.leftElement ? (
          <Flex mr="2" h="6" w="6" alignItems="center">
            {data.leftElement}
          </Flex>
        ) : null}
        {isCompact ? (
          <Text as="span" variant="body.mediumDemibold" noOfLines={1}>
            {label}
          </Text>
        ) : (
          <>
            <Box>
              <Text as="div" variant="body.mediumDemibold" noOfLines={1}>
                {label}
              </Text>
              <Text as="div" variant="body.medium" color="semantic.text.tertiary" noOfLines={1}>
                {address?.street} {address?.houseNumber}, {address?.zip} {address?.city}
              </Text>
            </Box>
            <Spacer />
            <SimpleGrid
              templateColumns="85px 115px" // fit-content(100%)
              spacingX={3}
              spacingY={0}
              hideBelow="md"
              flex="0 0 auto"
              ml={3}
            >
              <Text variant="body.smallMedium" color="semantic.text.tertiary">
                {t('global_texts.labels.merchantLocationId', 'Merchant location ID')}
              </Text>
              <Text variant="body.smallMedium" color="semantic.text.primary" noOfLines={1}>
                {merchantLocationId}
              </Text>
              {masterId && (
                <>
                  <Text variant="body.smallMedium" color="semantic.text.tertiary">
                    {t('global_texts.labels.locationId', 'Location ID')}
                  </Text>
                  <Text variant="body.smallMedium" color="semantic.text.primary" noOfLines={1}>
                    {masterId}
                  </Text>
                </>
              )}
            </SimpleGrid>
          </>
        )}
      </Flex>
      <Box ml="3" width="24px">
        {isSelected && (
          <SvgSpriteConsumer svgId={Icons.CHECK24} color="semantic.text.primary" size={24} />
        )}
      </Box>
    </components.Option>
  );
};

export default OptionComponent;
