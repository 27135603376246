import { Box } from '@chakra-ui/react';
import { SelectOption } from '@pluxee-design-system/react';
import { LocationFilterValues } from 'components/Locations/LocationFilter/types';
import VirtualizedList, { RenderComponentProps } from 'components/VirtualizedList';
import { Children, MutableRefObject, UIEvent } from 'react';
import Skeleton from 'react-loading-skeleton';
import { components, MenuListProps } from 'react-select';
import { LocationType, ProductLocationFilterValues } from './types';

interface ExtraComponentProps {
  data: LocationType[];
  hasNextPage: boolean;
  isEmpty: boolean;
  isLoadingPage: boolean;
  loadNextPage: (startIndex: number, stopIndex: number) => Promise<void>;

  // filters
  filterState: Partial<ProductLocationFilterValues> | Partial<LocationFilterValues>;
  handleScroll: (e: UIEvent<HTMLDivElement>) => void;
  scrollOffsetRef: MutableRefObject<number>;
}

const MenuListComponent = (
  { children, ...props }: MenuListProps<SelectOption, true>,
  {
    data,
    filterState,
    hasNextPage,
    isEmpty,
    isLoadingPage,
    loadNextPage,
    handleScroll,
    scrollOffsetRef,
  }: ExtraComponentProps,
) => {
  const childrenArray = Children.toArray(children);
  // const { getStyles } = props;
  const RenderOption = ({ index, isLoaded }: RenderComponentProps) => {
    const child = childrenArray[index];
    return (
      <div data-index={index}>
        {isLoaded ? (
          child
        ) : (
          <Box p={2}>
            <Skeleton height="28px" />
          </Box>
        )}
      </div>
    );
  };

  return (
    <components.MenuList {...props}>
      <VirtualizedList
        filterState={filterState}
        hasNextPage={hasNextPage}
        isEmpty={isEmpty}
        isNextPageLoading={isLoadingPage}
        loadNextPage={loadNextPage}
        items={data}
        itemSize={48}
        height={props.maxHeight - 8}
        initialScrollOffset={scrollOffsetRef.current}
        onScroll={handleScroll}
        pageSize={scrollOffsetRef.current === 0 ? Math.floor((props.maxHeight - 8) / 48) : 3}
        render={RenderOption}
        width="100%"
        renderInner={!(hasNextPage || isLoadingPage) && isEmpty ? children : undefined}
      />
    </components.MenuList>
  );
};

export default MenuListComponent;
